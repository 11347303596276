import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function HomeCards() {
  return (
    <div className='cards'>
      <h1>
        Acompáñanos en un día muy especial...
      </h1>
      <h2>
        Estamos honrados de recibirte en la ciudad que llamamos nuestro hogar,
        en uno de los días más importantes de nuestras vidas. 
        Aquí encontrarás todos los detalles sobre nuestro gran día, 
        desde los planes para la ceremonia y la recepción hasta información sobre viajes y más. 
        ¡Será un honor poder celebrar nuestro amor rodeados de las personas que más queremos! 
        Gracias por ser parte de nuestra historia.
        <br />
        Nohe y Vic
      </h2>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/CanRibas.jpg'
              text='Informacion general sobre la ubicación, horario, y transporte'
              label='Can Ribas de Montbui'
              path='/venue'
            />
            <CardItem
              src='images/SagradaFamilia.jpg'
              text='Que hacer en Barcelona, seguridad y desplazamiento en la ciudad'
              label='Barcelona'
              path='/barcelona'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/WHotel.jpeg'
              text='Barrios de Barcelona, hoteles, y airbnbs. Hospedaje en Montbui.'
              label='Alojamiento'
              path='/accomodation'
            />
            <CardItem
              src='images/BarCanete.jpg'
              text='Restaurantes recomendados para comida, cena, y desayuno. Sitios curiosos para tapear.'
              label='Restaurantes'
              path='/restaurants'
            />
            <CardItem
              src='images/Salon.jpg'
              text='Peluquerías, barberías, tintorerías, centros de spa y salones de belleza.'
              label='Belleza para él y ella'
              path='/beauty'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HomeCards;

import React from 'react';
import './Popup.css'; // Include the CSS file for styling

const Popup = ({ title, content, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-icon" onClick={onClose}>
          <i className='fas fa-times' />
        </span>
        <h2>{title}</h2> 
        <div className="popup-text">{content}</div>
      </div>
    </div>
  );
};

export default Popup;
import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './components/pages/Home';
import Venue from './components/pages/Venue';
import Barcelona from './components/pages/Barcelona';
import Accomodation from './components/pages/Accomodation';
import Beauty from './components/pages/Beauty';
import Restaurants from './components/pages/Restaurants';
import Subscribe from './components/pages/Subscribe';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {
  return (
    <>
    <Router>
      <ScrollToTop />
      <Navbar />
          <Routes>
            <Route path='/' exact element={ <Home />}></Route>
            <Route path='/venue' element={ <Venue />}></Route>
            <Route path='/barcelona' element={ <Barcelona />}></Route>
            <Route path='/accomodation' element={ <Accomodation />}></Route>
            <Route path='/beauty' element={ <Beauty />}></Route>
            <Route path='/restaurants' element={ <Restaurants />}></Route>
            <Route path='/subscribe' element={ <Subscribe />}></Route>
          </Routes>  
        <Footer />
    </Router>
    </>  
  );
}

export default App;

import React from 'react';
import './Cards.css';
import CardLink from './CardLink';

function BeautyCards() {
  return (
     <div className='cards'>
      <h1>
        Centros de Belleza y Cuidado Personal
      </h1>
      <p>
        Les compartimos algunos sitios que podrían serles útiles para prepararse antes de la boda.
      </p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
              <CardLink
              src='images/LePetitSalon.jpg'
              text='Le Petit Salon'
              label='Peluqueria'
              description='Especialistas en Peluqueria para Mujer.'
              url='https://maps.app.goo.gl/3pTYEEKFS4TwRmrY7'
              />
              <CardLink
              src='images/TWO.jpg'
              text='TWO Beauty Concept'
              label='Cejas y Pestañas'
              description='Salon de belleza muy céntrico, cerca de Plaza Cataluña. Además hacen servicios de Peluqueria, manicura, pedicura, masajes y laser.'
              url='https://maps.app.goo.gl/UVgsxEUX1CMZyRhC6'
              />
              <CardLink
              src='images/HelloNails.jpg'
              text='Hello Nails'
              label='Manicura/Pedicura'
              description='Cadena de especialistas en uñas, manos y pies. Reserven con tiempo. Están por toda la ciudad.'
              url='https://hellonails.com/en/our-centers/'
              />
        </ul>
        <ul className='cards__items'>
            <CardLink
              src='images/MDPerruquers.jpg'
              text='MD Perruquers'
              label='Peluqueria de Hombres'
              description='Barberia y Peluqueria de hombres en Les Corts.'
              url='https://maps.app.goo.gl/u17jWjF2vh3WzpB77'
            />
            <CardLink
              src='images/AIRE.jpg'
              text='AIRE Spa'
              label='SPA'
              description='SPA icónico de Barcelona, situado en el Born.'
              url='https://maps.app.goo.gl/r1G9hb64VGP81cBH6'
            />
            <CardLink
              src='images/rituels.jpg'
              text="Rituels d'Orient"
              label='SPA'
              description='SPA y Hammam, con servicios de masaje, faciales, y pedicura/manicura.'
              url='https://maps.app.goo.gl/Ex5MdA8ejkQP5cfXA'
            />
            <CardLink
              src='images/Tintoreria.jpg'
              text='Tintorería Solanes'
              label='Tintorería'
              description='Tintorería en Les Corts.'
              url='https://maps.app.goo.gl/D8NuHchTHA8earUN7'
            />
            <CardLink
              src='images/Maquillaje.jpg'
              text='Haircut Day'
              label='Peluquería y Maquillaje'
              description='Cadena de Belleza con servicios de Peluquería para Hombre y Mujer. Maquillistas profesionales.'
              url='https://haircutday.com/salon-de-belleza-barcelona'
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BeautyCards
import React from 'react'

function VenueInfo() {
const onClick =()=>{
    window.open("https://maps.app.goo.gl/vswJjRzGP95iMgTg8", '_blank'); // Open the URL in a new tab       
}
  return (
    <div className='cards'>
        <h1>
            ¿Cómo llegar?
        </h1>
        <p>
            Tendremos un servicio de bus saliendo desde Plaza de Francesc Macià a las 17:30h.<br/>
            Si vienes en coche, Can Ribas cuenta con servicio de parking para 300 vehículos.<br/>
            Te dejamos un link con la dirección desde Google Maps:<br/>
            <p1 onClick={onClick}>Accede a la Dirección </p1>
        </p>
        <br/>
        <h1>
            Horario
        </h1>
        <p>
            Recepción a las 18:30h<br/>
            Ceremonia Civil a las 19:00h<br/>
            Aperitivo de pie a las 20:00h<br/>
            Cena a las 21:30h<br/>
            Fiesta de 23:00h - 03:00h<br/>
        </p>
        <br/>
        <h1>
            ¿Cómo volver a Barcelona?
        </h1>
        <p>
            Tendremos un servicio de bus que volverá a la Plaza de Francesc Macià a las 00:00h y a las 03:00h<br/>
        </p>
        <br/>
        <h1>
            Vestimenta
        </h1>
        <p>
            La única condición que les pediremos es que nadie venga de blanco o similar.
        </p>
        <br/>
        <h1>
            Sobre los Niños
        </h1>
        <p>
            Nuestra celebración estará enfocada principalmente a adultos. Si bien nos encanta la presencia de los pequeños, no habrá personal dedicado especialmente a ellos. Rogamos a las personas adultas que les acompañen estén atentas y sean responsables de su bienestar.
        </p>
        <br/>
        <h1>
            Sobre el Regalo...
        </h1>
        <p>
            Agradeceríamos si puedes realizar tu regalo por medio de Transferencia (ES84 0182 4383 9802 0201 4481), Paypal(noheliazareth) o Bizum (cualquiera de nuestros whatsapps).
        </p>
    </div>
  )
}

export default VenueInfo
import React from 'react';
import './Cards.css';
import CardLink from './CardLink';

function AccomodationCards() {
  return (
    <div className='cards'>
      <h1>
        Alojamientos
      </h1>
      <p>
        Hemos recopilado algunos hoteles céntricos y seguros. También les compartimos algunos de Les Corts. Más adelante compartiremos sitios cerca de Can Ribas.
      </p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardLink
              src='images/Atenea.jpg'
              text='Atenea Barcelona'
              label='Les Corts'
              url='https://www.cityhotels.es/aparthotel-atenea-barcelona/'
              />
              <CardLink
              src='images/NHLesCorts.jpg'
              text='Hotel NH - Les Corts'
              label='Les Corts'
              url='https://www.nh-hotels.com/es/hotel/nh-barcelona-les-corts'
              />
              <CardLink
              src='images/NHConstanza.jpg'
              text='Hotel NH - Constanza'
              label='Les Corts'
              url='https://www.nh-collection.com/en/hotel/nh-collection-barcelona-constanza'
              />
        </ul>
        <ul className='cards__items'>
            <CardLink
              src='images/POLHotel.jpg'
              text='Pol & Grace Hotel'
              label='Gracia'
              url='https://www.polgracehotel.es/'
            />
            <CardLink
              src='images/StMoritz.jpg'
              text='Hotel St. Moritz'
              label="L'Eixample"
              url='https://www.hcchotels.com/en/st-moritz/'
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AccomodationCards
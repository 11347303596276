import React from 'react';
import './Cards.css';
import CardLink from './CardLink';

function RestaurantCards() {
  return (
    <div className='cards'>
      <h1>
        Restaurantes, Cafés, y Bares
      </h1>
      <p>
        Los que nos conocen, saben que somos unos amantes del buen comer. Así que hemos recopilado algunos de nuestros sitios favoritos en Barcelona.<br />
        Algunos restaurantes cuentan con menú de mediodía, lo cual sale muy a cuenta y otros tienen menú de degustación, para probar muchos platitos.<br />
        En nuestra lista, les dejamos una descripción y el link con la ubicación en Google Maps. Ahí encontrarás el número de teléfono o sitio web de cada restaurante para poder reservar.
        Les aconsejamos RESERVAR unos días antes, ya que en Barcelona la demanda siempre es alta. Les añadimos más detalles en la descripción.
        </p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardLink
              src='images/BarCanete2.jpg'
              text='Bar Cañete'
              label='Comida y Cena'
              description='Comida buenisíma en el centro de Barcelona. Si desean un poco de show cooking, reservar en la barra. Reservar con antelación.'
              url='https://maps.app.goo.gl/JF7ekxMrUJeEiZKd9'
              />
              <CardLink
              src='images/LaMundana.jpg'
              text='La Mundana'
              label='Comida y Cena'
              description='Restaurante recomendación Michelín en Sants. Pidan el huevo trufado. ¡No se arrepentirán! Reservar con antelación.'
              url='https://maps.app.goo.gl/pbWz6Libttv7x6kr9'
              />
              <CardLink
              src='images/Setze.jpg'
              text='Setze'
              label='Comida y Cena'
              description='Uno de nuestros favoritos del Barrio de Les Corts. Comida catalana con excelente servicio. Reservar con antelación.'
              url='https://maps.app.goo.gl/PHy8aFn9WnV81aaY6'
              />
              <CardLink
              src='images/latartareria.jpg'
              text='La Tartarería'
              label='Comida y Cena'
              description='Cuentan con menú degustación y también menú de mediodía. Su especialidad son tartares de todo tipo. Simplemente espectacular. Reservar con antelación.'
              url='https://maps.app.goo.gl/oFSZ5z5m6pnvhsWXA'
              />
        </ul>
        <ul className='cards__items'>
              <CardLink
              src='images/guri.jpg'
              text='Gurí'
              label='Comida y Cena'
              description='Cuentan con un menú degustación en Sants, ademas de una increíble atención. Reservar con antelación.'
              url='https://maps.app.goo.gl/2MudRvQcY29H37Pz7'
              />
              <CardLink
              src='images/retornino.jpg'
              text="O'Retorniño 2"
              label='Comida y Cena'
              description='Comida gallega en el centro Barcelona. Recomendamos la mariscada, el pulpo y el jamón ibérico.'
              url='https://maps.app.goo.gl/uy7gw1JLGKVrgBJ78'
              />
              <CardLink
              src='images/garage.jpg'
              text="Garage Coffee"
              label='Brunch'
              description='Brunch y café de especialidad en Les Corts. Servicio y comida de diez. Atienden por orden de llegada.'
              url='https://maps.app.goo.gl/xd7z8VMSYLbD6K656'
              />
           </ul>
        <ul className='cards__items'>
              <CardLink
              src='images/gats.jpg'
              text="Gats"
              label='Tapas'
              description='Restaurante de tapas en el centro de Barcelona. Pueden reservar normalmente el mismo día.'
              url='https://maps.app.goo.gl/A9etMySjnSBBQPx57'
              />
              <CardLink
              src='images/irati.jpg'
              text="Irati Taverna Basca"
              label='Tapas'
              description='Restaurante de pinchos bascos en el centro de Barcelona. Pueden reservar normalmente el mismo día.'
              url='https://maps.app.goo.gl/S8NDNkJ4uaAmifvHA'
              />
              <CardLink
              src='images/BillyBrunch.jpg'
              text="Billy Brunch"
              label='Brunch'
              description='Cadena de restaurantes de brunch dispersos en Barcelona. Atienden por orden de llegada. Pueden ver sus ubicaciones en el link.'
              url='https://billybrunch.com'
              />
              <CardLink
              src='images/hidden.jpg'
              text="Hidden Coffee"
              label='Café'
              description='Tienen dos ubicaciones en Barcelona - Les Corts y El Born. Muy recomendado. Atienden por orden de llegada.'
              url='https://maps.app.goo.gl/Ph8hA8XsgLj9WCCc9'
              />
        </ul>
        <ul className='cards__items'>
              <CardLink
              src='images/right.jpg'
              text="Right Side Coffee"
              label='Café'
              description='Café de especialidad en el centro de la ciudad. Atienden por orden de llegada.'
              url='https://maps.app.goo.gl/fYtbRggXJPHABQoa8'
              />
              <CardLink
              src='images/llibreria.jpg'
              text="La Llibreria"
              label='Coctelería'
              description='Es una joya escondida en el barrio de Les Corts. Reservar con antelación los fines de semana.'
              url='https://maps.app.goo.gl/3QhftXDj7fuZNfnE7'
              />
              <CardLink
              src='images/hemingway.jpg'
              text="Hemingway"
              label='Coctelería'
              description='Coctelería en el corazón del Eixample.'
              url='https://maps.app.goo.gl/S1Z41AzYkwnGiRx57'
              />
        </ul>
        </div>
      </div>
    </div>
  )
}

export default RestaurantCards
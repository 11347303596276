import React from 'react';
import '../../App.css';
import VenueHero from '../VenueHero';
import VenueInfo from '../VenueInfo';


function Venue() {
  return (
    <>
    <VenueHero />
    <VenueInfo/>
    </>
  )
}

export default Venue
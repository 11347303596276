import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './RSVP.css';
import ImageSlider from './ImageSlider';

function RSVP() {
  const images = [
    'images/DSC08717BYN.jpg',
    'images/DSC08780BYN.jpg',
    'images/DSC08828BYN.jpg',
    'images/DSC08856BYN.jpg'
  ];
  const [state, handleSubmit] = useForm("mqakzdnd");

  if (state.succeeded) {
      return (
      <div className='hero-container'>
      <ImageSlider images={images} />
      <h1>¡Gracias por tu respuesta!</h1>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <h1>Confirma tu Asistencia</h1><br/>
      <label htmlFor="name">
        Nombre(s)
      </label>
      <input
        id="name"
        type="text" 
        name="name"
        required
      />
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />

      <label htmlFor="lastname">
        Apellido(s)
      </label>
      <input
        id="lastname"
        type="text" 
        name="lastname"
        required
      />
      <ValidationError 
        prefix="Last Name" 
        field="lastname"
        errors={state.errors}
      />

      <label htmlFor="email">
        Email
      </label>
      <input
        id="email"
        type="email" 
        name="email"
        required
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      
      <fieldset>
        <legend>¿Vendrás a nuestra boda?</legend>
        <label>
          <input
            type="radio"
            name="attendance"
            value="true"
            required
          />
          Sí
        </label>
        <label>
          <input
            type="radio"
            name="attendance"
            value="false"
            required
          />
          No
        </label>
      </fieldset>

      <fieldset>
        <legend>¿Te suscribes a nuestra Newsletter?</legend>
        <label>
          <input
            type="radio"
            name="newsletter"
            value="true"
            required
          />
          Sí
        </label>
        <label>
          <input
            type="radio"
            name="newsletter"
            value="false"
            required
          />
          No
        </label>
        </fieldset>

      <label htmlFor="message">
        Mensaje 
      </label>
      <textarea
        id="message"
        name="message"
        placeholder="Coméntanos si vienes con tu pareja y/o niños. Por favor incluye cualquier restricción dietética, alergias, etc."
        required
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting}>
        Enviar
      </button>
    </form>
  );
}

export default RSVP;

import React from 'react';
import './HeroSection.css';

function VenueHero() {
  return (
     <div className='hero-container'>
      <img src='images/CanRibas.jpg' alt="" />
      <h1>Can Ribas de Montbui</h1>
      <p>Camí de Can Ribas, 08415 Bigues i Riells, Barcelona</p>
      <p>Viernes 9 de mayo del 2025</p>
      <p>Comenzamos a las 18:30h</p>
      </div>
  )
}

export default VenueHero
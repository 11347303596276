import React from 'react'

function RestaurantsHero() {
  return (
    <div className='hero-container'>
      <img src='images/BarCanete.jpg' alt="" />
      <h1>Restaurantes</h1>
      </div>
  )
}

export default RestaurantsHero
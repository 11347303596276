import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['main--btn', 'outline--btn'];
const SIZES = ['medium--btn', 'large--btn'];

export const Button = ({
    children, type, onClick, buttonStyle, buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
    return(
        <Link to='/subscribe' className='button-mobile'>
            <button 
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                    {children}
            </button>
        </Link>
    )
}
import React, { useState } from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import Popup from './Popup'; // Import the Popup component

function Footer() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupTitle, setPopupTitle] = useState('');

  const handleLinkClick = (content, title) => {
    setPopupContent(content);
    setPopupTitle(title);
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
    setPopupContent('');
    setPopupTitle(''); // Reset the title when closing the popup
  };

  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Suscríbete a nuestra Newsletter y no olvides confirmar tu asistencia antes del 15 de marzo del 2025
        </p>
        <p className='footer-subscription-text'>
          Puedes cancelar tu subscripción cuando quieras.
        </p>
        <Button buttonStyle='btn--outline'>RSVP</Button>
      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Acerca de</h2>
            <Link to='/' onClick={() => handleLinkClick('Nuestra historia comenzó en un momento inesperado, en medio de la pandemia, cuando el mundo parecía haberse detenido. Estaba en Barcelona, enfocada en mis estudios de maestría, cuando conocí a Víctor, un psiquiatra que, como muchos, estaba lidiando con el estrés y la incertidumbre de esos tiempos. A pesar de nuestras diferencias—yo de Honduras y él de España—desde el primer momento supimos que haríamos todo lo posible por estar juntos. Nos sorprendió descubrir cuántas cosas teníamos en común, como si el destino hubiera tejido nuestros caminos con pequeños detalles compartidos. Las dificultades nunca fueron obstáculos, sino una razón más para afianzar lo que sentíamos. Desde entonces, hemos crecido juntos, superando las adversidades y celebrando cada momento con amor y dedicación.', 'Nuestra Historia')}>
              Nuestra Historia
            </Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contáctanos</h2>
            <Link to='/' onClick={() => handleLinkClick('Como muchos saben, trabajo en un entorno de software. Así que he hecho la página web con mucho cariño. Si desean contactarme para crearles un sitio web, pueden escribirme a mi correo. Para aclarar cualquier duda más puedes contactarnos por correo noheliazareth@gmail.com y vchavarriaromero@gmail.com', 'Contacto')}>
              Contacto
            </Link>
          </div>
        </div>
      </div>
      <small className='website-rights'>NZB© {new Date().getFullYear()}.</small>

      {/* Render Popup if open */}
      {isPopupOpen && <Popup content={popupContent} onClose={handleClose} title={popupTitle} />}
    </div>
  );
}

export default Footer;
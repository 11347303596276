import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import HomeCards from '../HomeCards';

function Home() {
  return (
    <>
      <HeroSection />
      <HomeCards />
    </>
  );
}

export default Home;
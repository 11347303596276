import React from 'react'
import RestaurantsHero from '../RestaurantsHero'
import RestaurantCards from '../RestaurantCards'

function Restaurants() {
  return (
    <>
    <RestaurantsHero/>
    <RestaurantCards/>
    </>
  )
}

export default Restaurants
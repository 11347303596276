import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';

function Navbar() {
const [click, setClick] = useState(false);
const [button, setButton] = useState(true);
const handleClick = () => setClick(!click);
const closeMobileMenu = ()=> setClick(false);
const showButton = () => {
if (window.innerWidth <= 960) {
    setButton(false);
} else {
    setButton(true);
}
};

useEffect(() => {
showButton();
}, []);

window.addEventListener('resize', showButton);
  return (
    <>
        <nav className="navbar">
            <div className="navbar-container">
                <div className='menu-icon' onClick={handleClick}> 
                    <i className={click? 'fa-solid fa-x': 'fas fa-bars'}/>
                </div>
                <ul className={click? 'nav-menu active': 'nav-menu'}>
                    <li className="nav-item">
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>Inicio</Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/Venue' className='nav-links' onClick={closeMobileMenu}>Detalles de la Boda</Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/Barcelona' className='nav-links' onClick={closeMobileMenu}>Consejos de viaje en Barcelona</Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/Accomodation' className='nav-links' onClick={closeMobileMenu}>Alojamiento</Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/Restaurants' className='nav-links' onClick={closeMobileMenu}>Restaurantes</Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/Beauty' className='nav-links' onClick={closeMobileMenu}>Recomendaciones de Belleza</Link>
                    </li>
                </ul>
                {button && <Button buttonStyle='btn--outline'>RSVP</Button>}
            </div>
        </nav>
    </>
  )
}

export default Navbar
import React from 'react'

function CardLink(props) {
    const onClick = () => {
    if (props.url) {
      window.open(props.url, '_blank'); // Open the URL in a new tab
        }
     };
  return (
    <>
    <li className='cards__item' onClick={onClick}>
        <div className='cards__item__link'>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Link Card'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            <p className='cards__item__p'>{props.description}</p>
          </div>
        </div>
      </li>
    </>
  )
}

export default CardLink